<template>
  <div class="row mx-0">
    <div class="col-10 col-lg-6 mx-auto px-0 py-4">
      <div class="card p-3">
        <h3 class="col-12 my-3 text-center"> {{ ($route.path.slice(-5)=='criar') ?'Criar':'Editar' }} Usuário</h3>
        <label for="Nome">Nome:</label>
        <input id="Nome" v-model="form.display_name" type="text" placeholder="Nome" class="my-2 form-control" />
        <label for="usuario">Usuário:</label>
        <input id="usuario" v-model="form.user_login" @keypress="formating()" :disabled="$store.state.news.edit_user.id"
          type="text" placeholder="usuario" class="my-2 form-control" />
        <label for="password">Senha:</label>
        <input id="password" v-model="form.user_pass" type="password" placeholder="Nova senha"
          class="my-2 form-control" />
        <div class="row mx-0 justify-content-around">
          <button @click="$router.push('/noticias/usuarios')" :disabled="loading"
            class="col-auto my-3 btn btn-default border">Voltar</button>
          <button @click="action()" class="col-auto my-3 btn btn-success" :disabled="loading">Salvar
            <b-icon v-if="loading" icon="slack" class="mx-1" animation="spin" /></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          display_name: '',
          user_login: '',
          user_pass: ''
        }
      }
    },
    beforeMount() {
      if (this.$route.path == '/noticias/usuario/editar' && this.$store.state.news.edit_user.id) {
        this.form = this.$store.state.news.edit_user
      }
    },
    methods: {
      formating() {
        this.form.user_login = this.form.user_login.replace(/[^\w\s]/gi, '').toLowerCase();
      },
      action() {
        (this.$route.path == '/noticias/usuario/criar') ? this.createUser(): this.editUser();
      },
      createUser() {
        if (!this.form.display_name || !this.form.user_login || !this.form.user_pass)
          return this.swal(false, 'Preencha os dados corretamente!')

        this.loading = true
        this.$store.dispatch('createUser', this.form)
          .then((res) => [this.swal(true, res.mensagem), this.$router.push('/noticias/usuarios')])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false);
      },
      editUser() {
        if (!this.form.display_name || !this.form.user_login)
          return this.swal(false, 'Preencha os dados corretamente!')

        this.loading = true
        this.$store.dispatch('updateUser', this.form)
          .then((res) => [this.swal(true, res.mensagem), this.$router.push('/noticias/usuarios')])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false);
      }
    }
  }
</script>

<style scoped>

</style>